
.blur-wrapperf {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.blur-bgf {
  position: relative;
  z-index: 2;
} 

.blur-bgf::before {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: rgba(147, 252, 228, 0.3);
  border-radius: 50%;
  filter: blur(700px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
