
/* .blur-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  } */
  
  .blur-bg {
    position: relative;
    z-index: 1;
  }
  
  .blur-bg::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 1px;
    background: rgba(147, 252, 228, 0.2);
    border-radius: 50%;
    filter: blur(800px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  