
.blur-wrapper1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    
  }
  
  .blur-bg1 {
    position: relative;
    z-index: 1; 
  }
  
  .blur-bg1::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    background: rgba(147, 252, 228, 0.3);
    border-radius: 50%;
    filter: blur(500px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

 


  