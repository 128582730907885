.blur-wrapperp {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.blur-bgp {
  position: relative;
  z-index: 1;
}

.blur-bgp::before {
  content: '';
  position: absolute;
  width: 700px;
  height: 700px;
  background: rgba(147, 252, 228, 0.2);
  border-radius: 50%;
  filter: blur(500px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.phone___image__container {
  width: 294px;
  height: 600px;
}
.phone___image {
  width: 100% !important;
  height: 100%;
}


@media (max-width: 768px) {
    .phone___image__container {
      width: 200px;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .phone___image__container {
      width: 150px; 
      height: auto;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .phone___image__container {
      width: 170px;
    }
  }