
.blur-wrapperpri {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  
  .blur-bgpri {
    position: relative;
    z-index: 1;
  }
  
  .blur-bgpri::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    background: rgba(147, 252, 228, 0.2);
    border-radius: 50%;
    filter: blur(700px);
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  