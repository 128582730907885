/** index.css **/
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Neue machina';
  src: url('../src/assets/fonts/Neue-Machina/NeueMachina-Ultrabold.otf')
    format('truetype');
  font-weight: 100 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'neue machina';
  src: url('../src/assets/fonts/NeueMontreal-Regular.otf') format('truetype');
  font-weight: 100; /** Regular weight **/
  font-display: swap;
  font-style: normal;
}

:root {
  --font-title: 'Neue Machina', sans-serif;
  --font-body: 'Neue Montreal', sans-serif;
}

html {
  scroll-behavior: smooth;
}


html, body {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-body);
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Add these properties */
  width: 100%;
  min-height: 100vh;
}

/* Add this to ensure all containers respect the width */
#root {
  width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title);
}

span,
p,
div,
section,
main,
header,
footer,
i,
small,
ul,
li {
  font-family: var(--font-body);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

